<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import Form from '@/views/research/fact_research_roles/Form'
import vSelect from 'vue-select'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Index',
  components: {
    VueGoodTable,
    vSelect,
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    BForm,
    Form,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'Tên',
            field: 'name',
          },
          {
            label: 'Trạng thái',
            field: 'status',
          },
          {
            label: 'Thứ tự',
            field: 'orderNo',
          },
          {
            label: 'Thao tác',
            field: 'action',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10%',
            sortable: false,
          },
        ],
        rows: [],
      },
      filter: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
        code: '',
      },
      dataForm: {},
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      dataList: 'factResearchRoles/dataArr',
      total: 'factResearchRoles/total',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.FACT_RESEARCH_ROLE)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.FACT_RESEARCH_ROLE)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.FACT_RESEARCH_ROLE)
    },
  },
  async created() {
    await this.getInitData()
  },
  methods: {
    // handle even
    ...mapActions({
      getData: 'factResearchRoles/getData',
      deleteData: 'factResearchRoles/deleteData',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.getInitData()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.getInitData()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.getInitData()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.getInitData()
    },

    async getInitData() {
      this.isLoading = true
      try {
        const params = {
          currentPage: this.filter.page,
          itemsPerPage: this.filter.perPage,
        }
        await this.getData(params)
        this.table.rows = this.dataList
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    editItem(data) {
      this.dataForm = data
      this.$bvModal.show('form')
    },

    deleteItem(data) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: '',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Xóa',
          cancelButtonText: 'Hủy',
        })
        .then(async result => {
          if (result.isConfirmed) {
            try {
              const response = await this.deleteData(data.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                } else {
                  this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
                }
              }
            } catch (e) {
              this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
            } finally {
              await this.getInitData()
            }
          }
        })
    },

    showModal() {
      this.$bvModal.show('form')
      this.dataForm = {
        id: null,
        rowNum: null,
        name: '',
        status: 1,
      }
    },

    saveDataSuccess() {
      this.$bvModal.hide('form')
      this.getInitData()
    },

    getStatusName(id) {
      return this.statusOptions.find(status => status.id === id).label
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

  },
}
</script>

<style scoped>

</style>
